import { GuideItemTypeCategory } from "constants/guides/GuideItems.enums";
import { GuideItemTypeConfig } from "constants/guides/GuideItems.types";

import { KitchenApplianceTypes } from "./enums";

type Config = GuideItemTypeConfig<
  GuideItemTypeCategory.KITCHEN_APPLIANCE,
  KitchenApplianceTypes
>["texts"];

const itemNames: Config["itemNames"] = (t) => ({
  [KitchenApplianceTypes.RANGE_OVEN]: t("Range Oven"),
  [KitchenApplianceTypes.COOKTOP]: t("Cooktop"),
  [KitchenApplianceTypes.RANGETOP]: t("Rangetop"),
  [KitchenApplianceTypes.WALL_OVEN]: t("Wall Oven"),
  [KitchenApplianceTypes.KITCHEN_VENT]: t("Kitchen Vent"),
  [KitchenApplianceTypes.REFRIGERATOR_FREEZER]: t("Refrigerator Freezer"),
  [KitchenApplianceTypes.REFRIGERATOR_DRAWER]: t("Refrigerator Drawer"),
  [KitchenApplianceTypes.FREEZER_DRAWER]: t("Freezer Drawer"),
  [KitchenApplianceTypes.DISHWASHER]: t("Dishwasher"),
  [KitchenApplianceTypes.WINE_COOLER]: t("Wine Cooler"),
  [KitchenApplianceTypes.BEVERAGE_COOLER]: t("Beverage Cooler"),
  [KitchenApplianceTypes.WARMING_DRAWER]: t("Warming Drawer"),
  [KitchenApplianceTypes.TRASH_COMPACTOR]: t("Trash Compactor"),
  [KitchenApplianceTypes.ICE_MACHINE]: t("Ice Machine"),
  [KitchenApplianceTypes.MICROWAVE]: t("Microwave"),
});

const typeNames: Config["typeNames"] = (t) => ({
  [KitchenApplianceTypes.RANGE_OVEN]: t("Range Ovens"),
  [KitchenApplianceTypes.COOKTOP]: t("Cooktops"),
  [KitchenApplianceTypes.RANGETOP]: t("Rangetops"),
  [KitchenApplianceTypes.WALL_OVEN]: t("Wall Ovens"),
  [KitchenApplianceTypes.KITCHEN_VENT]: t("Kitchen Vents"),
  [KitchenApplianceTypes.REFRIGERATOR_FREEZER]: t("Refrigerator Freezers"),
  [KitchenApplianceTypes.REFRIGERATOR_DRAWER]: t("Refrigerator Drawers"),
  [KitchenApplianceTypes.FREEZER_DRAWER]: t("Freezer Drawers"),
  [KitchenApplianceTypes.DISHWASHER]: t("Dishwashers"),
  [KitchenApplianceTypes.WINE_COOLER]: t("Wine Coolers"),
  [KitchenApplianceTypes.BEVERAGE_COOLER]: t("Beverage Coolers"),
  [KitchenApplianceTypes.WARMING_DRAWER]: t("Warming Drawers"),
  [KitchenApplianceTypes.TRASH_COMPACTOR]: t("Trash Compactors"),
  [KitchenApplianceTypes.ICE_MACHINE]: t("Ice Machines"),
  [KitchenApplianceTypes.MICROWAVE]: t("Microwaves"),
});

export const KitchenAppliancesConfigTexts: Config = {
  categoryName: (t) => t("Kitchen Appliances"),
  itemName: (t) => t("Kitchen Appliance"),
  categoryDescription: (t) =>
    t(
      "Kitchen appliances are a key part of any kitchen setup, yet they’re often overshadowed by choices in countertops and cabinetry. No matter how great your kitchen design looks, it won’t function effectively until you choose appliances that fit the space and support your cooking needs."
    ),
  itemNames,
  typeNames,
};
