import { GuideItemTypeCategory } from "constants/guides/GuideItems.enums";
import { GuideItemTypeConfig } from "constants/guides/GuideItems.types";
import { RULE_DEFAULT_CANNOT_OVERLAP } from "pages/Guides/components/FloorPlan/PixiFloorPlanItems/PixiFloorPlanItem/PixiFloorPlanItem.rules";

import { KitchenApplianceTypes } from "./enums";

type Config = GuideItemTypeConfig<
  GuideItemTypeCategory.KITCHEN_APPLIANCE,
  KitchenApplianceTypes
>["floorPlan"];

const sizes: Config["sizes"] = {
  [KitchenApplianceTypes.RANGE_OVEN]: { width: 40, height: 40 },
  [KitchenApplianceTypes.RANGETOP]: { width: 40, height: 40 },
  [KitchenApplianceTypes.COOKTOP]: { width: 40, height: 40 },
  [KitchenApplianceTypes.WALL_OVEN]: { width: 40, height: 40 },
  [KitchenApplianceTypes.KITCHEN_VENT]: { width: 40, height: 40 },
  [KitchenApplianceTypes.REFRIGERATOR_FREEZER]: { width: 40, height: 40 },
  [KitchenApplianceTypes.REFRIGERATOR_DRAWER]: { width: 40, height: 40 },
  [KitchenApplianceTypes.FREEZER_DRAWER]: { width: 40, height: 40 },
  [KitchenApplianceTypes.DISHWASHER]: { width: 40, height: 40 },
  [KitchenApplianceTypes.WINE_COOLER]: { width: 40, height: 40 },
  [KitchenApplianceTypes.BEVERAGE_COOLER]: { width: 40, height: 40 },
  [KitchenApplianceTypes.WARMING_DRAWER]: { width: 40, height: 40 },
  [KitchenApplianceTypes.TRASH_COMPACTOR]: { width: 40, height: 40 },
  [KitchenApplianceTypes.ICE_MACHINE]: { width: 40, height: 40 },
  [KitchenApplianceTypes.MICROWAVE]: { width: 40, height: 40 },
};

const rules: Config["rules"] = {
  [KitchenApplianceTypes.RANGE_OVEN]: RULE_DEFAULT_CANNOT_OVERLAP,
  [KitchenApplianceTypes.RANGETOP]: RULE_DEFAULT_CANNOT_OVERLAP,
  [KitchenApplianceTypes.COOKTOP]: RULE_DEFAULT_CANNOT_OVERLAP,
  [KitchenApplianceTypes.WALL_OVEN]: RULE_DEFAULT_CANNOT_OVERLAP,
  [KitchenApplianceTypes.KITCHEN_VENT]: RULE_DEFAULT_CANNOT_OVERLAP,
  [KitchenApplianceTypes.REFRIGERATOR_FREEZER]: RULE_DEFAULT_CANNOT_OVERLAP,
  [KitchenApplianceTypes.REFRIGERATOR_DRAWER]: RULE_DEFAULT_CANNOT_OVERLAP,
  [KitchenApplianceTypes.FREEZER_DRAWER]: RULE_DEFAULT_CANNOT_OVERLAP,
  [KitchenApplianceTypes.DISHWASHER]: RULE_DEFAULT_CANNOT_OVERLAP,
  [KitchenApplianceTypes.WINE_COOLER]: RULE_DEFAULT_CANNOT_OVERLAP,
  [KitchenApplianceTypes.BEVERAGE_COOLER]: RULE_DEFAULT_CANNOT_OVERLAP,
  [KitchenApplianceTypes.WARMING_DRAWER]: RULE_DEFAULT_CANNOT_OVERLAP,
  [KitchenApplianceTypes.TRASH_COMPACTOR]: RULE_DEFAULT_CANNOT_OVERLAP,
  [KitchenApplianceTypes.ICE_MACHINE]: RULE_DEFAULT_CANNOT_OVERLAP,
  [KitchenApplianceTypes.MICROWAVE]: RULE_DEFAULT_CANNOT_OVERLAP,
};

const ruleSets: Config["ruleSets"] = {
  [KitchenApplianceTypes.RANGE_OVEN]: [],
  [KitchenApplianceTypes.RANGETOP]: [],
  [KitchenApplianceTypes.COOKTOP]: [],
  [KitchenApplianceTypes.WALL_OVEN]: [],
  [KitchenApplianceTypes.KITCHEN_VENT]: [],
  [KitchenApplianceTypes.REFRIGERATOR_FREEZER]: [],
  [KitchenApplianceTypes.REFRIGERATOR_DRAWER]: [],
  [KitchenApplianceTypes.FREEZER_DRAWER]: [],
  [KitchenApplianceTypes.DISHWASHER]: [],
  [KitchenApplianceTypes.WINE_COOLER]: [],
  [KitchenApplianceTypes.BEVERAGE_COOLER]: [],
  [KitchenApplianceTypes.WARMING_DRAWER]: [],
  [KitchenApplianceTypes.TRASH_COMPACTOR]: [],
  [KitchenApplianceTypes.ICE_MACHINE]: [],
  [KitchenApplianceTypes.MICROWAVE]: [],
};

export const KitchenApplianceConfigFloorPlan: Config = {
  sizes,
  rules,
  ruleSets,
};
