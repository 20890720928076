import { captureException } from "@sentry/react";
import { useMutation } from "@tanstack/react-query";
import { saveData as _saveData } from "api/homebase.api";
import PQueue, { Queue } from "p-queue";
import { useCallback } from "react";

import { OrchestratorMachineContext } from "./OrchestratorMachine/OrchestratorMachine.types";

type RunFn = () => Promise<void>;
export type DrainFn = () => Promise<void>;

const RETRY_COUNT = 3;

class SingleItemQueue implements Queue<RunFn, never> {
  private _backlog: RunFn | undefined = undefined;

  enqueue(run: RunFn) {
    this._backlog = run;
  }

  dequeue() {
    const value = this._backlog;
    this._backlog = undefined;
    return value;
  }

  get size() {
    return this._backlog === undefined ? 0 : 1;
  }

  filter() {
    const value = this._backlog;
    return value === undefined ? [] : [value];
  }
}

const queue = new PQueue({
  concurrency: 1,
  autoStart: true,
  queueClass: SingleItemQueue,
});

export const useSaveGlobalState = (saveFn: typeof _saveData) => {
  const { mutateAsync: saveState, error: saveStateError } = useMutation<
    void,
    Response,
    OrchestratorMachineContext
  >({
    mutationFn: saveFn,
    retry: (failureCount, response) => {
      return (
        queue.size === 0 &&
        queue.pending === 0 &&
        failureCount < RETRY_COUNT &&
        response.status !== 412 &&
        response.status !== 401
      );
    },
  });

  const saveData = useCallback(
    (data: OrchestratorMachineContext) => {
      queue.add(() => saveState(data)).catch(captureException);
    },
    [saveState]
  );

  const drainQueue = useCallback(() => {
    return queue.onIdle();
  }, []);

  return { saveData, saveStateError, drainQueue };
};
