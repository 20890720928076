export enum GuideType {
  DEMOLITION = "demolition",
  SITEWORK = "sitework",
  CONCRETE = "concrete",
  MASONRY = "masonry",
  FRAMING = "framing",
  SHEATHING = "sheathing",
  WATERPROOFING = "waterproofing",
  MECHANICAL = "mechanical",
  ELECTRICAL = "electrical",
  PLUMBING = "plumbing",
  INSULATION = "insulation",
  VENTILATION = "ventilation",
  WINDOWS_AND_DOORS = "windows_and_doors",
  KITCHEN_SPECIFIC = "kitchen_specific",
  BATHROOM_SPECIFIC = "bathroom_specific",
  WALL_AND_CEILING_MATERIAL = "wall_and_ceiling_material",
  FLOOR_MATERIAL = "floor_material",
  MILLWORK_AND_TRIM = "millwork_and_trim",
  PAINT_AND_FINISHES = "paint_and_finishes",
  HOME_SECURITY = "home_security",
  ROOF = "roof",
  EXTERIOR = "exterior_facade",
  WATER_FEATURES = "water_features",
  HARDSCAPE = "hardscape",
  LANDSCAPE = "landscape",
  // NOTE(clemens): below this point it's all fake guide types
  HOME_IMPROVEMENT = "home_improvement",
  GUIDE_HELP_ONLY = "guide_help_only",
  PRESET_GROUP_TYPE = "preset_group_type",
  ADD_ROOM_LAYOUT = "add_room_layout",
  TRY_IT_OUT_GUIDE = "try_it_out_guide",
}
