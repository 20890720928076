import { createContext, useContext } from "react";
import type { Interpreter } from "xstate";

import { DrainFn } from "./globalState";
import type { OrchestratorMachineContext } from "./OrchestratorMachine/OrchestratorMachine.types";

export interface GlobalStateContextValue {
  orchestrator: Interpreter<OrchestratorMachineContext>;
  forceReloadState: () => Promise<void>;
  drainQueue: DrainFn;
}

export const GlobalStateContext = createContext<GlobalStateContextValue>({
  orchestrator: null,
  forceReloadState: () => Promise.reject(),
  drainQueue: () => Promise.reject(),
});
export const useGlobalState = () => useContext(GlobalStateContext);
