import { getOrderedMap } from "common/utils/listUtils";
import { GuideItemTypeCategory } from "constants/guides/GuideItems.enums";
import { GuideItemTypeConfig } from "constants/guides/GuideItems.types";
import { GuideType } from "constants/guides/GuideType";
import { GuideIntentType } from "pages/Guides/enums";

import { KitchenLayoutConfigFloorPlan } from "./config.floorPlan";
import { KitchenLayoutConfigImages } from "./config.images";
import { KitchenLayoutConfigTexts } from "./config.texts";
import { KitchenLayoutTypes } from "./enums";

type Config = GuideItemTypeConfig<
  GuideItemTypeCategory.KITCHEN_LAYOUT,
  KitchenLayoutTypes
>;

const types: Config["types"] = [
  KitchenLayoutTypes.ONE_WALL,
  KitchenLayoutTypes.GALLEY,
  KitchenLayoutTypes.L_SHAPED,
  KitchenLayoutTypes.U_SHAPED,
  KitchenLayoutTypes.PENINSULA,
];

export const KitchenLayoutConfig: Config = {
  guideType: GuideType.KITCHEN_SPECIFIC,
  category: GuideItemTypeCategory.KITCHEN_LAYOUT,
  types,
  typesOrder: getOrderedMap(types),
  texts: KitchenLayoutConfigTexts,
  images: KitchenLayoutConfigImages,
  floorPlan: KitchenLayoutConfigFloorPlan,
  allowedInRooms: ["kitchen"],
  allowedIntents: [GuideIntentType.ADD],
} as const;
