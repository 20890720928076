import { getOrderedMap } from "common/utils/listUtils";
import { GuideItemTypeCategory } from "constants/guides/GuideItems.enums";
import { GuideItemTypeConfig } from "constants/guides/GuideItems.types";
import { GuideType } from "constants/guides/GuideType";

import { ShapesConfigFloorPlan } from "./config.floorPlan";
import { ShapesConfigImages } from "./config.images";
import { ShapesConfigTexts } from "./config.texts";
import { FloorPlanShapes } from "./enums";

type Config = GuideItemTypeConfig<
  GuideItemTypeCategory.SHAPES,
  FloorPlanShapes
>;

const types: Config["types"] = [
  FloorPlanShapes.SQUARE,
  FloorPlanShapes.RECTANGLE,
  FloorPlanShapes.L_SHAPED,
  FloorPlanShapes.T_SHAPED,
  FloorPlanShapes.U_SHAPED,
  FloorPlanShapes.CUSTOM,
];

export const ShapesConfig: Config = {
  guideType: GuideType.ADD_ROOM_LAYOUT,
  category: GuideItemTypeCategory.SHAPES,
  types,
  typesOrder: getOrderedMap(types),
  texts: ShapesConfigTexts,
  images: ShapesConfigImages,
  floorPlan: ShapesConfigFloorPlan,
  allowedInRooms: undefined,
  allowedIntents: undefined,
} as const;
