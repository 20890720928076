import { GuideItemTypeCategory } from "constants/guides/GuideItems.enums";
import { GuideItemTypeConfig } from "constants/guides/GuideItems.types";

import { KitchenLayoutTypes } from "./enums";

type Config = GuideItemTypeConfig<
  GuideItemTypeCategory.KITCHEN_LAYOUT,
  KitchenLayoutTypes
>["floorPlan"];

export const KitchenLayoutConfigFloorPlan: Config = {
  sizes: {
    [KitchenLayoutTypes.ONE_WALL]: { width: 40, height: 40 },
    [KitchenLayoutTypes.GALLEY]: { width: 40, height: 40 },
    [KitchenLayoutTypes.L_SHAPED]: { width: 40, height: 40 },
    [KitchenLayoutTypes.U_SHAPED]: { width: 40, height: 40 },
    [KitchenLayoutTypes.PENINSULA]: { width: 40, height: 40 },
  },
};
