import { GuideItemTypeCategory } from "constants/guides/GuideItems.enums";
import { GuideItemTypeConfig } from "constants/guides/GuideItems.types";

import { FloorPlanShapes } from "./enums";

type Config = GuideItemTypeConfig<
  GuideItemTypeCategory.SHAPES,
  FloorPlanShapes
>["floorPlan"];

export const ShapesConfigFloorPlan: Config = {
  sizes: {
    [FloorPlanShapes.SQUARE]: { width: 40, height: 40 },
    [FloorPlanShapes.RECTANGLE]: { width: 40, height: 40 },
    [FloorPlanShapes.L_SHAPED]: { width: 40, height: 40 },
    [FloorPlanShapes.U_SHAPED]: { width: 40, height: 40 },
    [FloorPlanShapes.T_SHAPED]: { width: 40, height: 40 },
    [FloorPlanShapes.CUSTOM]: { width: 15, height: 15 },
  },
};
