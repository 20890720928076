import { ReactComponent as IconKitchenSpecific } from "assets/icons/categories/kitchen_specific.svg";
import { ReactComponent as IconUnknown } from "constants/guides/Electrical/LightingFixtures/assets/icon_unknown.svg";
import { GuideItemTypeCategory } from "constants/guides/GuideItems.enums";
import { GuideItemTypeConfig } from "constants/guides/GuideItems.types";

import galleyLayoutFallback from "./assets/galleyLayout.png";
import galleyLayout from "./assets/galleyLayout.webp";
import lShapedLayoutFallback from "./assets/lShapedLayout.png";
import lShapedLayout from "./assets/lShapedLayout.webp";
import oneWallLayoutFallback from "./assets/oneWallLayout.png";
import oneWallLayout from "./assets/oneWallLayout.webp";
import peninsulaLayoutFallback from "./assets/peninsulaLayout.png";
import peninsulaLayout from "./assets/peninsulaLayout.webp";
import uShapedLayoutFallback from "./assets/uShapedLayout.png";
import uShapedLayout from "./assets/uShapedLayout.webp";
import { KitchenLayoutTypes } from "./enums";

type Config = GuideItemTypeConfig<
  GuideItemTypeCategory.KITCHEN_LAYOUT,
  KitchenLayoutTypes
>["images"];

const images: Config["images"] = {
  [KitchenLayoutTypes.ONE_WALL]: {
    src: oneWallLayout,
    fallbackSrc: oneWallLayoutFallback,
  },
  [KitchenLayoutTypes.GALLEY]: {
    src: galleyLayout,
    fallbackSrc: galleyLayoutFallback,
  },
  [KitchenLayoutTypes.L_SHAPED]: {
    src: lShapedLayout,
    fallbackSrc: lShapedLayoutFallback,
  },
  [KitchenLayoutTypes.U_SHAPED]: {
    src: uShapedLayout,
    fallbackSrc: uShapedLayoutFallback,
  },
  [KitchenLayoutTypes.PENINSULA]: {
    src: peninsulaLayout,
    fallbackSrc: peninsulaLayoutFallback,
  },
};
const icons: Config["icons"] = {
  [KitchenLayoutTypes.ONE_WALL]: IconUnknown,
  [KitchenLayoutTypes.GALLEY]: IconUnknown,
  [KitchenLayoutTypes.L_SHAPED]: IconUnknown,
  [KitchenLayoutTypes.U_SHAPED]: IconUnknown,
  [KitchenLayoutTypes.PENINSULA]: IconUnknown,
};

export const KitchenLayoutConfigImages: Config = {
  categoryIcon: IconKitchenSpecific,
  images,
  icons,
  defaultIcon: IconKitchenSpecific,
  defaultImage: {
    src: lShapedLayout,
    fallbackSrc: lShapedLayoutFallback,
  },
};
