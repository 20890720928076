import {
  OrchestratorGuideDoneEventData,
  OrchestratorPropertyContext,
} from "core/state/global/OrchestratorMachine/OrchestratorMachine.types";
import { GuideMachineId } from "pages/Guides/enums";
import { GuideContext } from "pages/Guides/GuidesMap.types";

export const processAddRoomLayoutGuideDone = (
  oldContext: OrchestratorPropertyContext,
  doneData: OrchestratorGuideDoneEventData
): OrchestratorPropertyContext => {
  const { originalRoomId } = doneData;

  const result =
    doneData.result as GuideContext[GuideMachineId.ADD_ROOM_LAYOUT];

  return {
    ...oldContext,
    rooms: oldContext.rooms.map((room) => {
      if (room.id !== originalRoomId) {
        return room;
      }

      return {
        ...room,
        roomPlan: {
          baseShape: result._baseShapeItem,
          wallMeasurements: result._wallMeasurementValues,
          originbaseShape: result._originBaseShape,
        },
      };
    }),
  };
};
