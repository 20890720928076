import { Point, Polygon, Segment } from "@flatten-js/core";
import { ColorSource } from "@pixi/core";
import { DropShadowFilter } from "@pixi/filter-drop-shadow";
import { Graphics } from "@pixi/graphics";
import { getDevicePixelRatio } from "common/utils/deviceUtils";
import {
  FloorPlanOriginBaseShape,
  Line,
  Points,
} from "components/HBFloorPlan/HBFloorPlan.types";
import { GuideItem } from "constants/guides/GuideItems.types";
import { cloneDeep } from "lodash-es";
import { FloorPlanItem } from "pages/Guides/components/FloorPlan/FloorPlanItems/FloorPlanItems.types";
import { floorPlanItemToPolygon } from "pages/Guides/components/FloorPlan/FloorPlanItems/FloorPlanItems.utils";
import { FloorPlanItemsContextType } from "pages/Guides/components/FloorPlan/PixiFloorPlanItems/context/FloorPlanItemsContext.types";
import { Coords } from "pages/Guides/types";
import { Polygon as PixiPolygon } from "pixi.js";
import { WALL_THICKNESS } from "shared/floorPlan/constants";
import {
  AREA_FILL_COLOR,
  WALL_COLOR,
  WALL_ELEVATION_OPTIONS,
} from "shared/floorPlan/constants.colors";

import {
  distanceFromCoordsToSegment,
  getAlignBetweenSegmentMidAndCoords,
  getSegmentExtensionAlign,
  isSegmentContainsPoint,
  mapPointsToConnectedLines,
} from "./line.utils";
import {
  flattenPoints,
  getAlignBetweenCoords,
  isEqualCoords,
  mapPointsToList,
} from "./points.utils";
import {
  checkShapeClosed,
  filterOverlappingVertices,
  getPolygonEdgeAlignment,
  mapSegmentToPolygon,
  offsetPolygon,
} from "./polygon.utils";

export interface BaseShapeOptions {
  interactive?: boolean;
  elevation?: boolean;
  border?: boolean;
  borderColor?: ColorSource;
  disableHitAreaMargin?: boolean;
  enableHitAreaPadding?: boolean;
  fillColorParams?: Parameters<Graphics["beginFill"]>;
  cursor?: string;
  originBaseShape?: FloorPlanOriginBaseShape;
}

interface MergeShapeParams {
  shape1: Points;
  shape2: Points;
  reverseShape2: boolean;
  removeFirst: boolean;
  atStart: boolean;
}

const HIT_AREA_MARGIN = WALL_THICKNESS;
const HIT_AREA_PADDING = WALL_THICKNESS / 2;

export const isShapeClosed = (shape?: Coords[]) => shape && shape.length > 2;

export const drawBaseShape = (
  g: Graphics,
  points: Coords[],
  options: BaseShapeOptions = {}
) => {
  const cleansedUpdatedPoints = filterOverlappingVertices(points);
  const path = flattenPoints(cleansedUpdatedPoints);

  if (checkShapeClosed(points)) {
    const fillParams = options.fillColorParams
      ? options.fillColorParams
      : [AREA_FILL_COLOR];
    g.beginFill(...fillParams);

    if (options.border) {
      const borderColor = options.borderColor ?? WALL_COLOR;
      g.lineStyle(WALL_THICKNESS, borderColor, 1, 0.5);
    }

    g.drawPolygon(path).endFill();
  } else {
    const borderColor = options.borderColor ?? WALL_COLOR;
    g.lineStyle({
      width: WALL_THICKNESS,
      color: borderColor,
    });
    g.moveTo(points[0].x, points[0].y);
    for (let i = 1; i < points.length; i++) {
      g.lineTo(points[i].x, points[i].y);
    }
  }

  if (options.interactive) {
    g.cursor = options.cursor ?? "pointer";
  }

  if (!options.disableHitAreaMargin) {
    const _offsetPolygon = offsetPolygon({
      points: cleansedUpdatedPoints,
      margin: HIT_AREA_MARGIN,
    });

    g.hitArea = new PixiPolygon(_offsetPolygon);
  } else if (options.enableHitAreaPadding) {
    const _offsetPolygon = offsetPolygon({
      points: cleansedUpdatedPoints,
      padding: HIT_AREA_PADDING,
    });
    g.hitArea = new PixiPolygon(_offsetPolygon);
  } else {
    g.hitArea = new PixiPolygon(path);
  }

  if (options.elevation) {
    const dropShadowFilter = new DropShadowFilter({
      ...WALL_ELEVATION_OPTIONS,
      resolution: getDevicePixelRatio(),
    });

    g.filters = [dropShadowFilter];
  } else {
    g.filters = [];
  }
};

export const mapBaseShapeToPolygonSegments = (points: Coords[]): Polygon[] => {
  const lines = mapPointsToConnectedLines(points);
  const shapes = lines
    .map(
      (l) => new Segment(new Point(l.p1.x, l.p1.y), new Point(l.p2.x, l.p2.y))
    )
    .map((S) => new Polygon([S]));

  return shapes;
};

export const mapBaseShapeToPolygons = (points: Coords[]) => {
  const segments = mapPointsToConnectedLines(points);

  return segments.map((s) =>
    mapSegmentToPolygon({ segment: [s.p1, s.p2], thickness: WALL_THICKNESS })
  );
};

export const getShapeBox = (shape: Coords[]) => {
  return new Polygon(mapPointsToList(shape)).box;
};

export const isShapesClearAvailable = (shapes: Points[]): boolean => {
  return shapes.length >= 1 && shapes[0].length >= 1;
};

// TODO(alan): figure out how to correctly handle multi-shape rooms
// see https://tree.taiga.io/project/homebase-engineering/us/1216
export const hackShapesToShape = (shapes: Points[]): Coords[] => {
  if (!shapes) {
    return undefined;
  }

  if (shapes.length === 0) {
    return undefined;
  }

  if (shapes.length > 1) {
    console.warn("This baseShape includes over 2 shapes!");
  }

  return shapes[0];
};

export const hackShapesToClosedShape = (shapes: Points[]): Coords[] => {
  const shape = hackShapesToShape(shapes);

  if (shape.length > 0 && !isShapeClosed(shape)) {
    return [...shape, shape[0]];
  }

  return shape;
};

const mergeShapes = (params: MergeShapeParams): Points => {
  const { shape1, shape2, reverseShape2, removeFirst, atStart } = params;
  if (reverseShape2) {
    shape2.reverse();
  }
  if (removeFirst) {
    shape2.shift();
  } else {
    shape2.pop();
  }
  if (atStart) {
    return shape2.concat(shape1);
  } else {
    return shape1.concat(shape2);
  }
};

/**
 * @description optimizeBaseShape is a function that
 * finds connected shapes from the original baseShapes,
 * merges them into continuous shapes, and optimizes the resulting shapes.
 */
export const optimizeBaseShape = (shapes: Points[]): Points[] => {
  const optimizedShapes: Points[] = cloneDeep(shapes);
  const shapesToRemove = new Set<number>();

  for (let i = 0; i < optimizedShapes.length; i++) {
    if (shapesToRemove.has(i)) {
      continue;
    }

    for (let j = i + 1; j < optimizedShapes.length; j++) {
      if (shapesToRemove.has(j)) {
        continue;
      }

      const firstCoordI = optimizedShapes[i][0];
      const lastCoordI = optimizedShapes[i][optimizedShapes[i].length - 1];
      const firstCoordJ = optimizedShapes[j][0];
      const lastCoordJ = optimizedShapes[j][optimizedShapes[j].length - 1];

      if (isEqualCoords(firstCoordI, firstCoordJ)) {
        optimizedShapes[i] = mergeShapes({
          shape1: optimizedShapes[i],
          shape2: optimizedShapes[j],
          reverseShape2: false,
          removeFirst: true,
          atStart: true,
        });
        shapesToRemove.add(j);
      } else if (isEqualCoords(firstCoordI, lastCoordJ)) {
        optimizedShapes[i] = mergeShapes({
          shape1: optimizedShapes[i],
          shape2: optimizedShapes[j],
          reverseShape2: false,
          removeFirst: false,
          atStart: true,
        });
        shapesToRemove.add(j);
      } else if (isEqualCoords(lastCoordI, firstCoordJ)) {
        optimizedShapes[i] = mergeShapes({
          shape1: optimizedShapes[i],
          shape2: optimizedShapes[j],
          reverseShape2: false,
          removeFirst: true,
          atStart: false,
        });
        shapesToRemove.add(j);
      } else if (isEqualCoords(lastCoordI, lastCoordJ)) {
        optimizedShapes[i] = mergeShapes({
          shape1: optimizedShapes[i],
          shape2: optimizedShapes[j],
          reverseShape2: true,
          removeFirst: false,
          atStart: false,
        });
        shapesToRemove.add(j);
      }
    }
  }

  return optimizedShapes.filter((_shape, index) => !shapesToRemove.has(index));
};

export const getAlignLinesFromBaseShapesAndOtherItems = (params: {
  shapes: Points[];
  otherItems: FloorPlanItem<GuideItem>[];
  position: Coords;
  ctx: FloorPlanItemsContextType;
  item: FloorPlanItem<GuideItem>;
}): { lines: Line[]; offset: Coords } => {
  const { shapes, otherItems, position, ctx, item } = params;
  const result: Line[] = [];
  let offset: Coords = { x: 0, y: 0 };

  shapes.forEach((shape) => {
    const wallCount = shape.length - 1;
    for (let i = 0; i < wallCount; i++) {
      const wall = { p1: shape[i], p2: shape[i + 1] };
      const segmentMidAlign = getAlignBetweenSegmentMidAndCoords({
        line: wall,
        coords: position,
      });
      if (segmentMidAlign) {
        result.push(segmentMidAlign.line);
        offset = {
          x: segmentMidAlign.offset.x ?? offset.x,
          y: segmentMidAlign.offset.y ?? offset.y,
        };
      }
      const extensionAlign = getSegmentExtensionAlign({
        line: wall,
        coords: position,
      });
      if (extensionAlign) {
        result.push(extensionAlign.line);
        offset = {
          x: extensionAlign.offset.x ?? offset.x,
          y: extensionAlign.offset.y ?? offset.y,
        };
      }
    }
  });

  const itemPolygon = floorPlanItemToPolygon({
    item,
    ctx,
    coords: position,
  }).item;

  otherItems.forEach((otherItem) => {
    const otherItemPolygon = floorPlanItemToPolygon({
      item: otherItem,
      ctx,
    }).item;
    const itemEdgeAlign = getPolygonEdgeAlignment({
      polygon1: itemPolygon,
      polygon2: otherItemPolygon,
    });
    result.push(...itemEdgeAlign.lines);
    offset = {
      x: itemEdgeAlign.offset.x ?? offset.x,
      y: itemEdgeAlign.offset.y ?? offset.y,
    };

    // Note(Alan): Regarding the design if two edge lines are present, skip checking the center alignment.
    if (itemEdgeAlign.lines.length === 2) {
      return;
    }

    const itemCoordsAlign = getAlignBetweenCoords({
      coords1: otherItem.item.coords,
      coords2: position,
    });
    if (itemCoordsAlign) {
      result.push(itemCoordsAlign.line);
      offset = {
        x: itemCoordsAlign.offset.x ?? offset.x,
        y: itemCoordsAlign.offset.y ?? offset.y,
      };
    }
  });

  return { lines: result, offset };
};

export const removeUnnecessaryCoordsFromShape = (
  shapes: Points[]
): Points[] => {
  return shapes.map((shape) => {
    if (!checkShapeClosed(shape)) {
      return shape;
    }

    return shape.filter((point, index, array) => {
      if (index === 0 || index === array.length - 1) {
        return true;
      }

      const prevPoint = array[index - 1];
      const nextPoint = array[index + 1];
      const segment: Line = { p1: prevPoint, p2: nextPoint };

      return !isSegmentContainsPoint(segment, point);
    });
  });
};

export const isShapeIncludeCoords = (
  shape: Coords[],
  coords: Coords
): boolean => {
  return shape.some((shapeCoords) => isEqualCoords(shapeCoords, coords));
};

export const scaleShapes = (shapes: Points[], scale: number): Points[] => {
  return shapes.map((shape) =>
    shape.map(({ x, y }) => ({
      x: x * scale,
      y: y * scale,
    }))
  );
};

export const isShapeWallsIncludeCoords = (
  shapes: Points,
  coords: Coords
): boolean => {
  const walls = mapPointsToConnectedLines(shapes);
  let isIncluded = false;
  walls.forEach((wall) => {
    const { distance } = distanceFromCoordsToSegment(coords, wall);
    if (distance <= WALL_THICKNESS) {
      isIncluded = true;
      return;
    }
  });
  return isIncluded;
};
