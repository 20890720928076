import { useQuery } from "@tanstack/react-query";
import { useActor, useSelector } from "@xstate/react";
import { fetchData, saveData as _saveDataRequest } from "api/homebase.api";
import { ConcurrentUpdateError } from "components/ConcurrentUpdateError/ConcurrentUpdateError";
import { clearStorages } from "core/persistence";
import {
  GlobalStateContext,
  GlobalStateContextValue,
} from "core/state/global/GlobalStateContext";
import { setActivePropertyId } from "core/state/global/OrchestratorMachine/actions.utils";
import { ORCHESTRATOR_ACTIONS } from "core/state/global/OrchestratorMachine/constants";
import { getOrchestratorMachine } from "core/state/global/OrchestratorMachine/OrchestratorMachine";
import type { OrchestratorMachineContext } from "core/state/global/OrchestratorMachine/OrchestratorMachine.types";
import { orchestratorContextSelector } from "core/state/hooks/useAppData";
import { useSendCallback } from "core/state/hooks/useSendCallback";
import { useStableNavigate } from "core/state/hooks/useStableNavigate";
import { omit } from "lodash-es";
import hashObject from "object-hash";
import {
  FC,
  memo,
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { ERROR, LOGIN } from "router/routes";
import { setupXStateDebugger, useInterpret } from "shared/util/xstate";

import { useSaveGlobalState } from "./globalState";

interface Props {
  onSaveData?: typeof _saveDataRequest;
  onFetchData?: typeof fetchData;
}

const RETRY_COUNT = 3;
const PROPERTY_ID = new RegExp("^/property/([0-9]+)/?");

const hashContext = (context: OrchestratorMachineContext) => {
  const data = omit(context, "ephemeral");
  const hash = hashObject(data);

  return { hash, data };
};

const GlobalStateProviderComponent: FC<Props & PropsWithChildren> = ({
  children,
  onSaveData = _saveDataRequest,
  onFetchData = fetchData,
}) => {
  const navigate = useStableNavigate();
  const [restored, setRestored] = useState(false);
  const { t } = useTranslation();

  const {
    data: serverState,
    error,
    isLoading,
    refetch,
  } = useQuery<OrchestratorMachineContext, Response>({
    queryKey: ["get_appdata"],
    queryFn: onFetchData,
    retry: (failureCount, response) => {
      return failureCount < RETRY_COUNT && response.status !== 401;
    },
    staleTime: Infinity,
  });

  const { saveData, saveStateError, drainQueue } =
    useSaveGlobalState(onSaveData);

  const [machine] = useState(() => getOrchestratorMachine({ navigate, t }));

  const isLoginRequired =
    error?.status === 401 || saveStateError?.status === 401;
  const isCriticalError = error != null && !isLoginRequired;
  const isConcurrentUpdate = saveStateError?.status === 412;

  const orchestrator = useInterpret(machine);
  useEffect(() => {
    setupXStateDebugger(orchestrator);
  }, [orchestrator]);
  const [state, send] = useActor(orchestrator);
  const restoreState = useSendCallback<OrchestratorMachineContext>(
    send,
    ORCHESTRATOR_ACTIONS.RESTORE_STATE
  );
  const defaultStateHash = useRef(
    hashContext(state.machine.initialState.context).hash
  );
  const localStateHash = useRef(defaultStateHash.current);
  const serverStateHash = useRef<string>();
  const localState = useSelector(orchestrator, orchestratorContextSelector);

  const onServerState = useCallback(
    (serverState: OrchestratorMachineContext) => {
      if (serverState) {
        // NOTE(clemens): ideally we'd useParams but this code runs before the
        //  router context is created up so we need to parse the URL ourselves
        const { data, hash } = hashContext(serverState);
        const match = window.location.pathname.match(PROPERTY_ID);
        if (match) {
          const propertyId = Number(match[1]);
          setActivePropertyId(data, propertyId);
        }
        serverStateHash.current = hash;
        restoreState(data);
      }

      if (serverState !== undefined) {
        // Have to use external flag or else app will render
        // with initial state instead of restored one.
        setRestored(true);
      }
    },
    [restoreState]
  );

  const forceReloadState = useCallback(async () => {
    await drainQueue();
    const response = await refetch({ throwOnError: true });
    onServerState(response.data);
  }, [drainQueue, onServerState, refetch]);

  useEffect(() => {
    const { data, hash: newHash } = hashContext(localState);
    const isDefaultState = newHash === defaultStateHash.current;
    const isLocalStateChanged = newHash !== localStateHash.current;
    const isServerStateUpToDate = newHash === serverStateHash.current;

    if (isLocalStateChanged && !isDefaultState && !isServerStateUpToDate) {
      saveData(data);
    }
    localStateHash.current = newHash;
  }, [localState, saveData]);

  useEffect(() => onServerState(serverState), [onServerState, serverState]);

  useEffect(() => {
    (async function () {
      if (isLoginRequired) {
        await clearStorages();
        await forceReloadState();
        navigate(LOGIN());
      }
    })();
  }, [forceReloadState, isLoginRequired, navigate]);

  useEffect(() => {
    if (isCriticalError) {
      navigate(ERROR(), { replace: true });
    }
  }, [isCriticalError, navigate]);

  const contextValue: GlobalStateContextValue = useMemo(
    () => ({
      orchestrator,
      forceReloadState,
      drainQueue,
    }),
    [orchestrator, forceReloadState, drainQueue]
  );

  if ((!restored && !isCriticalError) || isLoading) {
    // TODO: (pavel) loading page.
    //  see https://tree.taiga.io/project/homebase-engineering/us/191
    return null;
  }

  return (
    <GlobalStateContext.Provider value={contextValue}>
      {isConcurrentUpdate && <ConcurrentUpdateError />}
      {children}
    </GlobalStateContext.Provider>
  );
};

export const GlobalStateProvider = memo(GlobalStateProviderComponent);
