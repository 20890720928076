import { ArrayElement } from "common/utils/typeUtils";

import { Electrical } from "./Electrical/Electrical";
import { FloorMaterial } from "./FloorMaterial/FloorMaterial";
import { GuideItemTypeCategory } from "./GuideItems.enums";
import { GuideItemTypeConfig } from "./GuideItems.types";
import { Kitchen } from "./Kitchen/Kitchen";
import { Mechanical } from "./Mechanical/Mechanical";
import { MillworkAndTrim } from "./MillworkAndTrim/MillworkAndTrim";
import { RoomLayout } from "./RoomLayout/RoomLayout";
import { WallAndCeilingMaterial } from "./WallAndCeilingMaterial/WallAndCeilingMaterial";
import { WindowsAndDoors } from "./WindowsAndDoors/WindowsAndDoors";

// Note(pavel): We can't reference itself while definition, so to be able
// to get proper types we create this map as const,
// and get type of it to use below(see next note).
const _GuideItemCategoriesMap = {
  ...Electrical,
  ...Mechanical,
  ...WallAndCeilingMaterial,
  ...RoomLayout,
  ...WindowsAndDoors,
  ...MillworkAndTrim,
  ...FloorMaterial,
  ...Kitchen,
} as const;
type _GuideItemCategoriesMapType = typeof _GuideItemCategoriesMap;

export type GetGuideCategoryItemTypes<Category extends GuideItemTypeCategory> =
  ArrayElement<_GuideItemCategoriesMapType[Category]["types"]>;
export type GuideItemCategoriesMapType = {
  [C in GuideItemTypeCategory]: GuideItemTypeConfig<
    C,
    GetGuideCategoryItemTypes<C>
  >;
};
export type GuideItemsTypes = ArrayElement<
  GuideItemCategoriesMapType[GuideItemTypeCategory]["types"]
>;

type GuideItemTypesToCategories = {
  [C in keyof GuideItemCategoriesMapType as GetGuideCategoryItemTypes<C>]: C;
};
export type GetGuideItemCategoryByType<Type extends GuideItemsTypes> =
  GuideItemTypesToCategories[Type];

// Note(pavel): At first this looks like the same variable, but now
// it has strict type-checking (we need it later in utils). For example, if we forgot to define some
// GuideItemTypeCategory it will throw TS-error, so we must define
// all of them in _GuideItemCategoriesMap above.
export const GuideItemCategoriesMap: GuideItemCategoriesMapType =
  _GuideItemCategoriesMap;
