import { GuideItemTypeCategory } from "constants/guides/GuideItems.enums";
import { GuideItemTypeConfig } from "constants/guides/GuideItems.types";

import { KitchenLayoutTypes } from "./enums";

type Config = GuideItemTypeConfig<
  GuideItemTypeCategory.KITCHEN_LAYOUT,
  KitchenLayoutTypes
>["texts"];

const itemNames: Config["itemNames"] = (t) => ({
  [KitchenLayoutTypes.ONE_WALL]: t("One Wall"),
  [KitchenLayoutTypes.GALLEY]: t("Galley"),
  [KitchenLayoutTypes.L_SHAPED]: t("L Shaped"),
  [KitchenLayoutTypes.U_SHAPED]: t("U Shaped"),
  [KitchenLayoutTypes.PENINSULA]: t("Peninsula"),
});
const typeNames: Config["typeNames"] = (t) => ({
  [KitchenLayoutTypes.ONE_WALL]: t("One Wall"),
  [KitchenLayoutTypes.GALLEY]: t("Galley"),
  [KitchenLayoutTypes.L_SHAPED]: t("L Shaped"),
  [KitchenLayoutTypes.U_SHAPED]: t("U Shaped"),
  [KitchenLayoutTypes.PENINSULA]: t("Peninsula"),
});

export const KitchenLayoutConfigTexts: Config = {
  categoryName: (t) => t("Kitchen Layout"),
  itemName: (t) => t("Kitchen Layout"),
  categoryDescription: (t) => t("Kitchen Layout"),
  itemNames,
  typeNames,
};
