import { transform } from "api/utils";
import { isShapeClosed } from "shared/floorPlan/utils/baseShape.utils";

import { AppDataV18 } from "./models/AppDataV18";
import { AppDataV19 } from "./models/AppDataV19";

export function mapV18ToV19(data: AppDataV18): AppDataV19 {
  return transform(data, (result) => {
    result.state.version = 19;

    Object.values(result.properties).forEach((property) => {
      property.data.rooms.forEach((room) => {
        if (!room.roomPlan) {
          return;
        }

        if (room.roomPlan.originbaseShape) {
          return;
        }

        const baseShape = room.roomPlan.baseShape.shapes[0];

        if (isShapeClosed(baseShape)) {
          room.roomPlan.originbaseShape = baseShape;
        }
      });
    });
  });
}
